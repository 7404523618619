/*Theme    : assan
 * Author  : Design_mylife
 * Version : V1.8
 *
 */

$( window ).resize(function() {
    $(".navbar-collapse").css({ maxHeight: $(window).height() - $(".navbar-header").height() + "px" });
    $(".top-bar-light").unstick();
    $(".sticky").unstick();
    $(".sticky").sticky({topSpacing: 40});
    $(".top-bar-light").sticky({topSpacing: 0});
});

var searchTempl = "<div class='searchDiv noprint' style=''><label style=''>Filter: <input type='text' placeholder=''></label></div>";
function showFilter(searchTmpl) {
	   $("#Listnav").sieve({ itemSelector: "li", searchTemplate: searchTmpl });
}

$(document).ready(function () {
	var lexi = $('#Listnav');
	if(lexi.length) {
		lexi.listnav({
	        initLetter: listnavInitLetter,
	        includeAll: true,
	        flagDisabled: false,
	        noMatchText: 'Keine Einträge vorhanden',
	        showCounts: false,
	        // cookieName: 'listnav',
	        onClick: function(letter) {
	     	   var definition = $('#definition');
	            $('#Listnav').css('display', 'block');
	            if(definition.length) {
	         	   definition.remove();
	         	   showFilter(searchTempl);
	            }
	            $('.searchDiv input').val('');

	        }
		});
	}

	if(!$('#definition').length) {
		 	   showFilter(searchTempl);
	}

	//sticky header on scroll
	 $(window).load(function () {
		 $(".sticky").sticky({topSpacing: 40});
		 $(".top-bar-light").sticky({topSpacing: 0});
	 });

	//parallax
	$(window).stellar({
	         horizontalScrolling: false,
	         responsive: true/*,
	          scrollProperty: 'scroll',
	          parallaxElements: false,
	          horizontalScrolling: false,
	          horizontalOffset: 0,
	          verticalOffset: 0*/
	});

	//Counter Up
	$('.counter').counterUp({
	        delay: 10,
	        time: 800
	});

	// Wow
    var wow = new WOW(
            {
                boxClass: 'wow', // animated element css class (default is wow)
                animateClass: 'animated', // animation css class (default is animated)
                offset: 100, // distance to the element when triggering the animation (default is 0)
                mobile: false        // trigger animations on mobile devices (true is default)
            }
    );
    wow.init();

	//OWL CAROUSEL
	$("#clients-slider").owlCarousel({
	    autoPlay: 3000,
	    pagination: false,
	    items: 4,
	    itemsDesktop: [1199, 3],
	    itemsDesktopSmall: [991, 2]
	});

	/*========tooltip and popovers====*/
	$("[data-toggle=popover]").popover();
	$("[data-toggle=tooltip]").tooltip();

	//transparent header
	 $(window).scroll(function () {
	        if ($(this).scrollTop() > 100) {
	            $('.transparent-header').css("background", "#252525");
	        } else {
	            $('.transparent-header').css("background", "transparent");
	        }
	 });

});



/***********************************************************
     * ACCORDION
***********************************************************/
$('.panel-ico a[data-toggle="collapse"]').on('click', function () {
        if ($(this).closest('.panel-heading').hasClass('active')) {
            $(this).closest('.panel-heading').removeClass('active');
        } else {
            $('.panel-heading a[data-toggle="collapse"]').closest('.panel-heading').removeClass('active');
            $(this).closest('.panel-heading').addClass('active');
        }
});

